














import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class PasswordInput extends Vue {
  passwordValue = '';
  passwordVisible = false;

  @Prop() value!: string;
  @Prop({ required: false, default: 'Password' }) label;
  @Prop({ required: false, default: false }) showPasswordRequirements;
  @Prop({ required: false, default: false }) hasError;

  @Watch('passwordValue')
  @Emit('input')
  onPasswordValueChanged(val) {
    return val;
  }

  get passwordInputType() {
    return this.passwordVisible ? 'text' : 'password';
  }

  get visibilityIcon() {
    return this.passwordVisible ? 'visibility_off' : 'visibility';
  }

  mounted() {
    this.passwordValue = this.value;
  }
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
}
