//if (!globalThis?.URLSearchParams) { globalThis.URLSearchParams = require("url").URLSearchParams; }
//if (!globalThis?.fetch) { globalThis.fetch = require("node-fetch"); }

// import { getFuturiLoginInstance } from "./auth"
import { CloudSSOLoginRequest, CloudSSOUser } from "./types/auth"
import { SearchableItem } from "./types/search"

export class StatusNotAcceptableError extends Error {}

type TokenFunc = () => Promise<string>
type Headers = Record<string, any>
type FetchOpts = Record<string, any>

class Credentials {
    private _tokenFunc?: TokenFunc

    set tokenFunc(value: TokenFunc) {
        console.log('[cloudsso-client] setting token credentials function')
        this._tokenFunc = value
    }

    async transformFetchOpts(opts: FetchOpts): Promise<FetchOpts> {
        if ((process as any)?.versions?.node?.length) {
            const { CLOUDSSO_API_SECRET } = process.env
            if (CLOUDSSO_API_SECRET?.length) {
                console.log('post: making call using ApiSecret')

                const res = { ...opts }
                opts.headers = opts.headers ?? {}
                opts.headers['authorization'] = `ApiSecret ${CLOUDSSO_API_SECRET}`
                if ('credentials' in res) {
                    delete res.credentials
                }
                return res
            }
        }

        if (this._tokenFunc) {
            const token = await this._tokenFunc()
            console.log('token: ', token)
            if (token?.length) {
                const res = { ...opts }
                delete res.credentials
                return res
            }
        }

        return opts
    }

    async transformHeaders(headers: Headers): Promise<Headers> {
        if (this._tokenFunc) {
            const token = await this._tokenFunc()
            if (token?.length) {
                return { ...headers,
                    Authorization: `Bearer ${token}`
                }
            }
        }

        return headers
    }
}

const _credentials = new Credentials()

export function setFuturiCredentialsFunction(value: TokenFunc) {
    _credentials.tokenFunc = value
}

export async function get<T>(url: string): Promise<T> {
    // await (await getFuturiLoginInstance()).ensureLoggedIn()

    const defaultHeaders = { 'accept': 'application/json' }
    const headers = await _credentials.transformHeaders(defaultHeaders)

    const defaultOpts = {
        method: 'get',
        headers,
        credentials: 'include',
    }

    const opts = await _credentials.transformFetchOpts(defaultOpts)

    const resp = await fetch(url, opts)
    if (resp.status !== 200 && resp.status !== 304) {
        throw new StatusNotAcceptableError()
    }
    
    const text = await resp.text()
    console.log(`util: get: body: <<<${text}>>>`)

    return JSON.parse(text)
}

export async function post<D, T>(url: string, data: D): Promise<T> {
    // await (await getFuturiLoginInstance()).ensureLoggedIn()

    const defaultHeaders = data instanceof URLSearchParams ? {} : {"content-type": "application/json"}

    const body = data instanceof URLSearchParams ? data : JSON.stringify(data)
    const headers = await _credentials.transformHeaders(defaultHeaders)

    const defaultOpts = {
        method: 'post',
        body,
        headers,
        credentials: 'include',
    }
    const opts = await _credentials.transformFetchOpts(defaultOpts)

    const resp = await fetch(url, opts)
    
    const text = await resp.text()
    console.log(`util: post: resp body: <<<${text}>>>`)

    return JSON.parse(text)
}

export function groupBy<T> (arr: SearchableItem<T>[], key: string): Record<string, SearchableItem<T>[]> {
    return arr.reduce((o: Record<string, SearchableItem<T>[]>, v) => {
        const value = v?.indexMap?.get(key)
        if (value) {
            o[value] = (o[value] || []).concat([v])
        }
        return o
    }, {})
}

export const searchable = (s: string) => s.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')
