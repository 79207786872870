


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import LoginContainer from '../components/LoginContainer.vue';
import Message from '../components/Message.vue';

import { requestPasswordReset } from '../utils/passwordReset';

@Component({
  components: { LoginContainer, Message },
})
export default class PasswordResetRequestSuccess extends Vue {
  // @Getter user!: UserModel;
  @Getter loginUrl!: string;
  // @Getter productName!: string;

  @Prop({ required: false })
  productName = 'TOPLINE';

  @Prop({ required: false })
  emailAddress?: string;

  canRequestResend = true;
  errors: string[] = [];

  async resendClicked() {
    const productType = this.productName?.toUpperCase();
    const errors = await requestPasswordReset(this.loginUrl, productType, this.emailAddress!);
    if (errors && errors.length) {
      console.error(errors);
      this.errors = errors;
    } else {
      this.canRequestResend = false;
    }
  }

  mounted() {
    this.$nextTick(() => {
      if (!this.emailAddress?.length) {
        this.$router.replace({ name: 'Login' });
      }
    });
  }
}
