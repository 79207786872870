


















import 'vue-router';
import LoginContainer from '../components/LoginContainer.vue';
import Message from '../components/Message.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { LoginContainer, Message },
})
export default class SignUp extends Vue {
  @Prop({ required: false, default: undefined }) reason?: string;
  @Prop({ required: false, default: 'Login' }) redirect?: string;

  readonly supportPhoneNumber?: string = '877.221.7979';
  readonly supportPhoneNumberExtension?: string = '1';

  get supportTelLink(): string {
    let link = `tel:${this.supportPhoneNumber}`;
    if (this.supportPhoneNumberExtension) {
      link += `,${this.supportPhoneNumberExtension}`;
    }
    return link;
  }
}
