import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/views/Login.vue';
import SignUp from '@/views/SignUp.vue';
import SignUpError from '@/views/SignUpError.vue';
import PasswordResetRequest from '@/views/PasswordResetRequest.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import PasswordResetRequestSuccess from '@/views/PasswordResetRequestSuccess.vue';
import ConfirmationCode from '@/views/ConfirmationCode.vue';
import Logout from '@/views/Logout.vue';
import Migrate from '@/views/Migrate.vue';
import ConfirmMigration from '@/views/ConfirmMigration.vue';
import ContactSupport from '@/views/ContactSupport.vue';

Vue.use(VueRouter);

const allowAnonymousMeta = { AllowAnonymous: true };
const preventAuthenticatedMeta = { PreventAuthenticated: true };

const routes: Array<RouteConfig> = [
  {
    path: '/',
    alias: '/interactions/:uid',
    name: 'Login',
    component: Login,
    props: ({ query: { switchAccount, message } }) => ({ switchAccount, message }),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
    props: ({ query: { token } }) => ({ token }),
  },
  {
    path: '/signup-error',
    name: 'SignUpError',
    component: SignUpError,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
    props: ({ query: { product, email } }) => ({ productName: product, emailAddress: email }),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: PasswordResetRequest,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
  },
  {
    path: '/migrate',
    name: 'Migrate',
    component: Migrate,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
    props: ({ query: { token } }) => ({ token }),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
    props: ({ query: { un, code } }) => ({ username: un, code }),
  },
  {
    path: '/password-reset-requested',
    name: 'PasswordResetRequestSuccess',
    component: PasswordResetRequestSuccess,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
    props: ({ query: { product, email } }) => ({ productName: product, emailAddress: email }),
  },
  {
    path: '/confirm-migration',
    name: 'ConfirmMigration',
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
    component: ConfirmMigration,
    props: ({ query: { email, firstName } }) => ({ email, firstName }),
  },
  {
    path: '/confirmation-code', // Handle query param for the email passed code and auto post form with the value
    name: 'ConfirmationCode',
    component: ConfirmationCode,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
    props: ({ query: { emailAddress, confirmCode } }) => ({ emailAddress, confirmCode }),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    props: ({ query: { url } }) => ({ url }),
  },
  {
    path: '/contact-support',
    name: 'ContactSupport',
    component: ContactSupport,
    props: ({ query: { reason, redirect } }) => ({ reason, redirect }),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
