






























import { Component, Vue } from 'vue-property-decorator';
import LoginContainer from '../components/LoginContainer.vue';
import { Getter } from 'vuex-class';
import UserModel from '@/model/UserModel';
import axios from 'axios';

@Component({
  components: { LoginContainer },
})
export default class ConfirmMigration extends Vue {
  @Getter productName!: string;
  @Getter productDisplayName!: string;
  @Getter user!: UserModel;
  @Getter loginUrl!: string;

  migrate() {
    const payload = {
      email: this.user.email,
      password: this.user.password,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      migration: {
        details: {
          productType: this.productName,
          productUsername: this.user.productUsername,
        },
        credentials: {
          productPassword: this.user.productPassword,
        },
      },
    };
    // console.log(payload);
    axios.post(`${this.loginUrl}/account/signup`, payload).then((res) => {
      if (res.data.success) {
        this.$router.push({ name: 'ConfirmationCode' });
      }
      console.log(res);
    });
  }

  navigateTo(routeName) {
    this.$router.push({ name: routeName });
  }
  mounted() {
    this.$nextTick(() => {
      if (!this.user.email) {
        this.$router.replace({ name: 'Login' });
      }
    });
  }
}
