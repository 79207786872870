








































































import 'vue-router';
import { Component, Prop, Vue } from 'vue-property-decorator';
import LoginContainer from '../components/LoginContainer.vue';
import { Action, Getter } from 'vuex-class';
import { loginPost } from '@futuri/cloudsso-client/src/client/login';
import { RequestPostLoginCredentialResponse } from '@futuri/cloudsso-client';
import { LoginStatus } from '@futuri/cloudsso-client/src/client/types/auth';
import Message from '../components/Message.vue';
import axios from 'axios';
import UserModel from '../model/UserModel';
import { gotoOidcHomeForProduct, validateEmailAddress } from '../utils';
import PasswordInput from '../components/PasswordInput.vue';

@Component({
  components: { PasswordInput, LoginContainer, Message },
})
export default class Login extends Vue {
  @Getter isLoggedIn!: boolean;
  @Getter authToken!: string;
  @Getter productName!: string;
  @Getter productDisplayName!: string;
  @Getter loginUrl!: string;
  @Getter user!: UserModel;
  @Getter isMigrated!: boolean;
  @Action logIn;
  @Action setUser;
  @Action setProductName;

  @Prop({ required: false }) message?: string;

  get messageText() {
    if (this.message?.length) {
      return this.message;
    } else if (window.localStorage?.getItem('flash_message')?.length) {
      const message = window.localStorage?.getItem('flash_message');
      window.localStorage.removeItem('flash_message');
      return message;
    }
    return undefined;
  }

  @Prop({ required: false, default: true }) switchAccount;

  username = '';
  password = '';
  readonly tabIndex = 1;
  productPassword = '';
  productUsername = '';
  errors: string[] = [];
  showInvalidLinkMessage = false;
  loading = true;
  errorMessage = '';
  errorHeader = 'Error';
  showInvalidCredentials = false;
  showInvalidEmailAddress = false;

  async loginButtonClicked() {
    this.showInvalidEmailAddress = false;
    if (this.username && this.password) {
      if (this.isEmailLogin && !validateEmailAddress(this.username)) {
        this.showInvalidEmailAddress = true;
        return;
      }
      this.user.email = this.username;
      this.user.password = this.password;

      await this.login();
    }
  }

  async login() {
    this.errors = [];
    const loginPostUrl = new URL(location.href);
    loginPostUrl.pathname += '/login';

    try {
      const resp: any = await loginPost<any>(loginPostUrl.href, this.user.email, this.user.password);

      if (resp.success) {
        const redirectUrl = resp?.redirectUrl;

        localStorage['migrated'] = true; // Since they already have cloud login, thye are migrated
        if (redirectUrl?.length) {
          this.setUser(new UserModel());
          setTimeout(() => {
            location.href = redirectUrl;
          }, 100);
        }
        return;
      }

      // Early returned if success. Error handling below this point.

      let errorHeader = 'Invalid email and/or password';
      let error = 'Invalid email or password';

      if ('status' in resp) {
        // This doesn't happen as of 2022-02-09. but we planned to,
        // so I moved the handler out of here for readability
        const mappedError = this.mapLoginStatusToError(resp.status);
        error = mappedError.error;
        errorHeader = mappedError.errorHeader;
      } else if (resp.error_reason == 'Invalid email or password') {
        // This is the expected error for invalid creds.
        // Zena-approved
        errorHeader = 'Please double-check your email address and password and try again.';
        error = '<a href="/forgot-password">Want to reset your password? Just click here.</a>';
      } else {
        // Any other error would be unknown to us. "contact support" messaging
        // Zena-approved
        errorHeader = 'Whoops! An error has occurred.';
        error = `It’s not you, it’s us. Our team is working on an issue temporarily impacting login. Please contact VIP Support for help accessing ${this.productDisplayName}.`;
      }

      this.errorHeader = errorHeader;
      this.errors.push(error);
      this.errorMessage = error;
    } catch (e) {
      // Most likely reason to land here is a bad interaction code. If e.g. login was restarted since interaction started.
      // The backend tries to redirect the ajax to the product, which fails due to cors.
      // Refreshing the page will start a new interaction so the next try succeeds.
      this.errors.push('Unable to log in');
      // Zena-approved
      this.errorMessage =
        'It’s not you, it’s us. An error has occurred, but <a href="/">clicking this link</a> to reload the page should take care of the issue.';
      this.errorHeader = 'Error';
    }
  }

  // Unused as of 2022-02-09, the login endpoint doesn't use the usual status constants.
  mapLoginStatusToError(status = '') {
    let error = '';
    let errorHeader = '';

    switch (status) {
      // These seem like the same issue, use initial values for error message
      case LoginStatus.EMAIL_EXISTS:
      case LoginStatus.INVALID_USER_CREDENTIALS:
        break;
      case LoginStatus.ALREADY_LINKED:
        errorHeader = 'Account is already linked';
        error = 'Account is already linked';
        break;
      case LoginStatus.ALREADY_MIGRATED:
        errorHeader = 'Account is already migrated';
        error = 'Account is already migrated';
        break;
      case LoginStatus.CODE_MISMATCH:
        errorHeader = 'Codes do not match';
        error = 'Codes do not match';
        break;
      case LoginStatus.EMAIL_INVALID:
        errorHeader = 'Email is invalid';
        error = 'Email is invalid';
        break;
      case LoginStatus.EXPIRED_CODE:
        errorHeader = 'Code has expired';
        error = 'Code has expired';
        break;
      case LoginStatus.INVALID_PRODUCT_CREDENTIALS:
        errorHeader = 'Invalid product credentials';
        error = 'Invalid product credentials';
        break;
      case LoginStatus.LOCKED:
        errorHeader = 'Account is locked';
        error = 'Account is locked';
        break;
      case LoginStatus.PASSWORD_DOES_NOT_MEET_REQUIREMENTS:
        errorHeader = 'Password does not meet requirements. Passwords must be at least 6 characters';
        error = 'Password does not meet requirements. Passwords must be at least 6 characters';
        break;
      case LoginStatus.PENDING_CONFIRMATION:
        errorHeader = 'Confirmation is pending';
        error = 'Confirmation is pending';
        break;
      case LoginStatus.PENDING_PASSWORD_RESET_CONFIRMATION:
        errorHeader = 'Password reset confirmation is pending';
        error = 'Password reset confirmation is pending';
        break;
      case LoginStatus.UNABLE_TO_SEND_EMAIL:
        errorHeader = 'Unable to send email';
        error = 'Unable to send email';
        break;
      case LoginStatus.USER_MUST_CHANGE_PASSWORD:
        errorHeader = 'User must change password';
        error = 'User must change password';
        break;
      // If these statuses come back for success == false there might be a problem, use default case
      // case LoginStatus.UNKNOWN_ERROR:
      // case LoginStatus.CONFIRMED:
      // case LoginStatus.CAN_MIGRATE:
      // case LoginStatus.CAN_MIGRATE_WITH_TOKEN:
      // case LoginStatus.SUCCESS:
      default:
        errorHeader = 'An unknown error occurred';
        error = 'An unknown error occurred';
        break;
    }
    return { error, errorHeader };
  }

  migrateWithToken() {
    console.log('migrateWithToken: productUsername: ', this.user.productUsername);
    console.log('migrateWithToken: token: ', this.user.token);
    const payload = {
      details: {
        productType: this.productName,
        productUsername: this.user.productUsername,
      },
      credentials: {
        productToken: this.user.token,
      },
    };
    axios.post(`${this.loginUrl}/account/validateProductAccount`, payload).then((res) => {
      if (res.data.success && res.data.status === 'CAN_MIGRATE_WITH_TOKEN') {
        this.$router.push({
          name: 'SignUp',
          query: {
            email: this.user.email,
            token: this.user.token,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
          },
        });
      } else {
        console.log('Showing invalid link');
        // this.showInvalidLinkMessage = true;
        this.$router.push({
          name: 'SignUpError',
        });
      }
    });
  }

  migrate() {
    this.validateProductForm();
    if (this.errors.length === 0) {
      this.user.productUsername = this.productUsername;
      this.user.productPassword = this.productPassword;
      this.setUser(this.user);
      const payload = {
        details: {
          productType: this.productName,
          productUsername: this.productUsername,
        },
        credentials: {
          productPassword: this.productPassword,
        },
      };
      axios.post(`${this.loginUrl}/account/validateProductAccount`, payload).then((res) => {
        if (res.data.success) {
          if (res.data.status === 'CAN_MIGRATE') {
            this.$router.push({ name: 'Migrate' });
          } else if (res.data.status === 'INVALID_PRODUCT_CREDENTIALS') {
            // this.errors.push('Invalid Credentials');
            this.showInvalidCredentials = true;
          }
        } else {
          this.showInvalidCredentials = true;
          // this.errors.push('Invalid Credentials');
        }
        this.loading = false;
      });
    }
  }

  validateProductForm() {
    this.errors = [];
    if (!this.productUsername) this.errors.push('Please enter a username');
    if (!this.productPassword) this.errors.push('Please enter a password');
  }

  get isEmailLogin() {
    const productName = this.productName.toLowerCase();
    return productName === 'topline' || productName === 'prepplus';
  }

  created() {
    this.$nextTick(() => {
      const qs = new URLSearchParams(location.search);
      const migrateEmail = qs.get('email');
      const migrateToken = qs.get('token');
      if (migrateEmail?.length && migrateToken?.length) {
        console.log('migrateEmail: ', migrateEmail);
        this.user.productUsername = migrateEmail;
        this.user.token = migrateToken;
      }

      // if (this.user.password && this.user.email) {
      //   this.tabIndex = 1;
      //   this.login();
      // } else
      if (this.user.token && this.user.productUsername) {
        this.loading = true;
        this.migrateWithToken();
      }
      // No longer need to change tabIndex, only login allowed now
      // else if (this.isMigrated) {
      //   this.tabIndex = 1;
      // }
    });
  }

  mounted() {
    const query = this.$route.query;

    this.$nextTick(() => {
      /**
       * Per a design decision to help alleviate login issues for users,
       * we are defaulting the tabIndex to 1 so all users start on the
       * "Log In Here" tab, even if they are not migrated. I am deliberately
       * not changing the state of localStorage['migrated'] so as not to break
       * any of the code that handles migrations. I'm also leaving the old code
       * commented out below with the new code immediately following it.
       */
      // if (localStorage['migrated']) {
      //   this.tabIndex = 1;
      // }
      // TabIndex starts as 1 now
      // this.tabIndex = 1;
      if (!location.pathname.match(/^\/interactions\//)) {
        gotoOidcHomeForProduct(this.productName);
      }

      if (query.error_message) {
        this.errorMessage = `${query.error_message}`;
        this.errorHeader = 'Error';
        this.errors.push(this.errorMessage);
        // tabIndex doesnt change, only 1 is allowed
        // this.tabIndex = 1;
      }
      document.title = this.productDisplayName || 'Futuri Login';
    });

    if (!this.productName) {
      if (query.product) {
        this.setProductName(query.product);
        localStorage['productName'] = query.product;
        this.loading = false;
      } else {
        const product = localStorage['productName'];
        if (product) {
          this.setProductName(product);
          // gotoOidcHomeForProduct(product);
        } else {
          this.loading = false;
        }
      }
    } else {
      this.loading = false;
    }
  }
}
