const maskEmail = (emailAddress: string): string => {
  const maskPart = (part: string): string => part.substr(0, 1) + '*'.repeat(3);
  if (!emailAddress.includes('@')) {
    return maskPart(emailAddress);
  }
  const parts = emailAddress.split('@');
  const username = maskPart(parts[0]);
  const domainParts = parts[1].split('.');
  const tld = domainParts.slice(-1);
  const domain = domainParts.slice(0, -1).join('.');
  const maskedDomain = maskPart(domain) + '.' + tld;
  return username + '@' + maskedDomain;
};

const validatePasswordRequirements = (pw: string): boolean => {
  return (
    !!pw &&
    // Leaving old regex tests at Tim Meade's request
    // old: Adding User Pool Password Requirements - Amazon Cognito
    // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
    // /\d/.test(pw) &&
    // /[a-z]/.test(pw) &&
    // /[A-Z]/.test(pw) &&
    // /[=+\-^$*.[\]{}()?"!@#%&\\,/><':;|_~`]/.test(pw) &&
    // Chris asked that requirements be changed to only min 6 chars
    pw.length >= 6
  );
};

/**
 * This is an extremely basic email validator that checks if email has '@' and a '.'
 * characters, with any other characters around them. (e.g. "a@b.c" is valid)
 * @param {String} email email address
 * @returns {Boolean} true if valid email address
 */
const validateEmailAddress = (email: string): boolean => {
  return !!email && /^.+@.+\..+$/.test(email);
};

const gotoOidcHomeForProduct = (productName: string, query?: Record<string, string>): void => {
  let uri = `${window.location.origin}/productLogin?product=${productName}`;
  if (query) {
    const qs = new URLSearchParams();
    for (const [k, v] of Object.entries(query)) {
      qs.set(k, v);
    }
    uri += `&${qs.toString()}`;
  }
  window.location.href = uri;
};

export { maskEmail, validatePasswordRequirements, gotoOidcHomeForProduct, validateEmailAddress };
