/* eslint-disable @typescript-eslint/camelcase */

export enum LoginStatus {
    SUCCESS = 'SUCCESS',
    EMAIL_INVALID = 'EMAIL_INVALID',
    EMAIL_EXISTS = 'EMAIL_EXISTS',
    PASSWORD_DOES_NOT_MEET_REQUIREMENTS = 'PASSWORD_DOES_NOT_MEET_REQUIREMENTS',
    CAN_MIGRATE = 'CAN_MIGRATE',
    CAN_MIGRATE_WITH_TOKEN = 'CAN_MIGRATE_WITH_TOKEN',
    PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
    CONFIRMED = 'CONFIRMED',
    PENDING_PASSWORD_RESET_CONFIRMATION = 'PENDING_PASSWORD_RESET_CONFIRMATION',
    LOCKED = 'LOCKED',
    EXPIRED_CODE = 'EXPIRED_CODE',
    CODE_MISMATCH = 'CODE_MISMATCH',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',

    // Cognito account UserStatus
    USER_MUST_CHANGE_PASSWORD = 'USER_MUST_CHANGE_PASSWORD', // FORCE_CHANGE_PASSWORD

    // Migration errors
    ALREADY_LINKED = 'ALREADY_LINKED',
    ALREADY_MIGRATED = 'ALREADY_MIGRATED',

    // Cognito errors
    UNABLE_TO_SEND_EMAIL = 'UNABLE_TO_SEND_EMAIL',

    // Other errors
    INVALID_USER_CREDENTIALS = 'INVALID_USER_CREDENTIALS',
    INVALID_PRODUCT_CREDENTIALS = 'INVALID_PRODUCT_CREDENTIALS',
    NO_PRODUCT_ACCOUNT = 'NO_PRODUCT_ACCOUNT',
}

export interface CloudSSOUser {
    access_token: string
}

export interface CloudSSOLoginRequest {
    redirect_uri: string
    request_scopes?: [string]
}

export interface CloudSSOLoginResponse {
    user?: CloudSSOUser
    login_uri?: string
}
