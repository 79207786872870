import UserModel from '@/model/UserModel';
import axios from 'axios';

export const requestPasswordReset = async (
  loginUrl: string,
  productType: string,
  email: string
): Promise<string[] | undefined> => {
  const errors: string[] = [];

  try {
    const res = await axios.post(`${loginUrl}/account/passwordReset`, { email, productType });

    console.log('passwordReset: res.data: ', res.data);

    if (res.data.success || res.data.status === 'SUCCESS') {
      return undefined;
    } else {
      if (res.data.status === 'NO_PRODUCT_ACCOUNT' || res.data.status === 'INVALID_PRODUCT_CREDENTIALS') {
        errors.push('Account not found'); // not meant to show to end user.
        // PasswordResetRequest will convert to marketing-approved copy.
        // PasswordResetRequestSuccess would only see this in extreme edge case, if their account was deleted while they were waiting to click Resend.
      } else if (res.data.status === 'RATE_LIMIT_EXCEEDED') {
        errors.push(
          'For your security, your account has been temporarily locked after too many attempts to reset your password. Please wait 15-30 minutes before trying again; if you’re still having issues, contact <a href="https://support.futurimedia.com/">Futuri VIP Support</a> for assistance.'
        );
      } else {
        // Unknown error, if server provided message, use it.
        // Otherwise, show default.
        errors.push(
          res.data.message ||
            'It’s not you, it’s us. An error has occurred, but <a href="/forgot-password">clicking this link</a> to reload the page should take care of the issue.'
        );
      }
    }
  } catch (e) {
    console.error(e);
    errors.push(
      'It’s not you, it’s us. An error has occurred, but <a href="/forgot-password">clicking this link</a> to reload the page should take care of the issue.'
    );
  }

  if (errors.length) {
    return errors;
  }
  return undefined;
};
