<template>
  <div id="app" :class="theProductName">
    <router-view />
  </div>
</template>

<style type="text/css">
body {
  margin: 0;
  /*height: calc(100%);*/
  background-size: contain;
}

#app {
  height: 100vh;
}

a:hover {
  text-decoration: none;
}

a {
  color: var(--accent-links);
}
</style>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({})
export default class LoginContainer extends Vue {
  @Getter productName;
  @Action setProductName;

  get theProductName() {
    return (this.productName ?? '').toLowerCase();
  }

  mounted() {
    const query = this.$route.query;

    if (!this.productName) {
      if (query.product) {
        this.setProductName(query.product);
        localStorage['productName'] = query.product;
      } else {
        const product = localStorage['productName'];
        if (product) {
          this.setProductName(product);
        } else {
          // Temporary while this is only fronting TopLine login:
          this.setProductName('TOPLINE');
        }
      }
    }
  }
}
</script>
