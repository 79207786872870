




import 'vue-router';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component
export default class Logout extends Vue {
  @Action logOut;

  @Prop({ required: false })
  url?: string;

  logout() {
    this.logOut();
    window.location.href = this.url || process.env.VUE_PORTAL_BASE_URL || 'http://localhost:8080';
  }

  mounted() {
    this.logout();
  }
}
