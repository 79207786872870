









































































import 'vue-router';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import LoginContainer from '../components/LoginContainer.vue';
import Message from '../components/Message.vue';
import { Action, Getter } from 'vuex-class';
import UserModel from '../model/UserModel';
import axios from 'axios';
import { validatePasswordRequirements, validateEmailAddress, gotoOidcHomeForProduct } from '../utils';
import PasswordInput from '../components/PasswordInput.vue';

@Component({
  components: { PasswordInput, LoginContainer, Message },
})
export default class Migrate extends Vue {
  @Getter productName!: string;
  @Getter productDisplayName!: string;
  @Getter user!: UserModel;
  @Getter loginUrl!: string;
  @Action setUser;

  @Prop({ required: false, default: undefined }) token?: string;

  firstName = '';
  lastName = '';
  email = '';
  password = '';
  confirmPassword = '';
  canShowErrors = false;
  formErrors: string[] = [];
  errorToInputMap = {};

  // learnMoreClicked() {
  //   alert('learn more!');
  // }

  migrate() {
    this.canShowErrors = true;
    this.validateForm();
    if (this.formErrors.length === 0) {
      this.user.firstName = this.firstName;
      this.user.lastName = this.lastName;
      this.user.email = this.email;
      this.user.password = this.password;

      this.setUser(this.user);

      const payload = {
        email: this.user.email,
        password: this.user.password,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        migration: {
          details: {
            productType: this.productName,
            productUsername: this.user.productUsername,
          },
          credentials: {
            productPassword: this.user.productPassword,
          },
        },
      };
      // console.log(payload);
      axios.post(`${this.loginUrl}/account/signup`, payload).then((res) => {
        if (res.data.success) {
          this.$router.push({ name: 'ConfirmationCode' });
        } else {
          localStorage['migrated'] = true;
          this.formErrors.push('<a href="/">An account already exists with this email. Click here to log in</a>.');
          this.errorToInputMap['email'] = true;
        }
      });
    }
  }

  validateForm() {
    this.formErrors = [];
    this.errorToInputMap = {};
    const valid = validatePasswordRequirements(this.password);

    if (!valid) {
      this.errorToInputMap['password'] = true;
      this.formErrors.push('Password must meet the requirements');
    }
    if (this.password !== this.confirmPassword) {
      this.errorToInputMap['confirmPassword'] = true;
      this.formErrors.push('Passwords must match');
    }
    // Not requiring first name last name if token is provided
    if (!this.user.token) {
      if (!this.firstName) {
        this.errorToInputMap['firstName'] = true;
        this.formErrors.push('First Name is required');
      }
      if (!this.lastName) {
        this.formErrors.push('Last Name is required');
        this.errorToInputMap['lastName'] = true;
      }
    }
    if (!validateEmailAddress(this.email)) {
      this.errorToInputMap['email'] = true;
      this.formErrors.push('Valid email is required');
    }
  }

  goToHome() {
    gotoOidcHomeForProduct(this.productName);
  }

  @Watch('password')
  onPasswordChanged() {
    this.canShowErrors && this.validateForm();
  }

  @Watch('confirmPassword')
  onConfirmPasswordChanged() {
    this.canShowErrors && this.validateForm();
  }

  @Watch('email')
  onEmailChanged() {
    this.canShowErrors && this.validateForm();
  }

  @Watch('firstName')
  onFirstNameChanged() {
    this.canShowErrors && this.validateForm();
  }

  @Watch('lastName')
  onLastNameChanged() {
    this.canShowErrors && this.validateForm();
  }
}
