











































import 'vue-router';
import { Component, Vue, Prop } from 'vue-property-decorator';
import LoginContainer from '../components/LoginContainer.vue';
import Message from '../components/Message.vue';
import { gotoOidcHomeForProduct, maskEmail } from '../utils';
import { Action, Getter } from 'vuex-class';
import axios from 'axios';
import UserModel from '../model/UserModel';

@Component({
  components: { LoginContainer, Message },
})
export default class ConfirmationCode extends Vue {
  @Getter user!: UserModel;
  @Getter loginUrl!: string;
  @Getter productName!: string;
  @Action setUser;
  @Action setIsMigrated;

  @Prop({ required: false, default: undefined }) confirmCode?: string;

  verificationCode = '';
  errors: string[] = [];
  messages: string[] = [];

  confirmClicked() {
    this.errors = [];
    this.messages = [];

    if (!this.verificationCode) {
      this.errors.push('Please enter a validation code');
    }
    axios
      .post(`${this.loginUrl}/account/confirmSignup`, {
        email: this.user.email,
        confirmationCode: this.verificationCode,
      })
      .then((res) => {
        if (res.data.success) {
          this.setUser(new UserModel());
          this.setIsMigrated(true);
          window.localStorage['migrated'] = true;
          gotoOidcHomeForProduct(this.productName);
        } else {
          this.errors.push('The validation code is either invalid or expired.');
        }
      });
  }

  resendCode() {
    this.messages = [];
    this.errors = [];
    axios.post(`${this.loginUrl}/account/resendConfirmation`, { email: this.user.email }).then((res) => {
      if (res.data.success) {
        this.messages.push(res.data.message);
      } else {
        this.errors.push('There was an error sending the confirmation code. Please try again in a while');
      }
    });
  }

  get maskedEmail() {
    if (this.user.email) return maskEmail(this.user.email);
    return '';
  }

  mounted() {
    this.$nextTick(() => {
      if (!this.user.email) {
        this.$router.replace({ name: 'Login' });
      }
    });
    // If the confirmCode query param is set, copy value to verificationCode and process as if user submitted
    if (this.confirmCode) {
      this.verificationCode = this.confirmCode;
      this.confirmClicked();
    }
  }
}
