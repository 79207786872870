export default class UserModel {
  username!: string;
  password!: string;
  token!: string;
  productUsername!: string;
  productPassword!: string;
  firstName!: string;
  lastName!: string;
  email!: string;
}
