







































import 'vue-router';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import LoginContainer from '../components/LoginContainer.vue';
import UserModel from '../model/UserModel';
import Message from '../components/Message.vue';
import { gotoOidcHomeForProduct, validateEmailAddress } from '../utils';
import { requestPasswordReset } from '../utils/passwordReset';

@Component({
  components: { LoginContainer, Message },
})
export default class PasswordResetRequest extends Vue {
  @Getter isLoggedIn!: boolean;
  @Getter loginUrl!: string;
  @Getter user!: UserModel;
  @Getter productName!: string;
  @Action setUser;

  emailAddress = '';
  canShowErrors = false;
  errors: string[] = [];

  async resetClicked() {
    this.canShowErrors = true;
    this.validateForm();
    if (this.errors.length === 0) {
      const productType = this.productName?.toUpperCase();
      const email = this.emailAddress;
      const errors = await requestPasswordReset(this.loginUrl, productType, email);

      if (errors?.length) {
        this.errors = errors;
      } else {
        this.user.email = email;
        this.$router.push({
          name: 'PasswordResetRequestSuccess',
          query: {
            productName: this.productName,
            email: email,
          },
        });
      }
    }
  }

  goToLogin() {
    gotoOidcHomeForProduct(this.productName);
  }

  validateForm() {
    this.errors = [];
    if (!validateEmailAddress(this.emailAddress)) this.errors.push('Valid email is required');
  }

  @Watch('emailAddress')
  onEmailAddressChanged() {
    this.canShowErrors && this.validateForm();
  }
}
