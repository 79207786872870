












import 'vue-router';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import LoginContainer from '../components/LoginContainer.vue';

@Component({
  components: { LoginContainer },
})
export default class SignUpError extends Vue {
  // @Getter user!: UserModel;
  @Getter loginUrl!: string;
  // @Getter productName!: string;

  @Prop({ required: false })
  productName = 'TOPLINE';

  @Prop({ required: false })
  emailAddress?: string;

  forgotPassword() {
    this.$router.replace({ name: 'PasswordResetRequest' });
  }
}
