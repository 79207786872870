import Vue from 'vue';
import Vuex from 'vuex';
// import VuexPersistence from 'vuex-persist';
import UserModel from '@/model/UserModel';

Vue.use(Vuex);

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
// });

export default new Vuex.Store({
  state: {
    authToken: '',
    isMigrated: false,
    productName: '',
    user: new UserModel(),
    loginUrl: process.env.VUE_APP_FUTURI_SSO_LOGIN_ENDPOINT || `${location.origin}/api/v1`,
  },
  mutations: {
    setAuthToken: (state, authToken) => {
      state.authToken = authToken;
    },
    setProductName: (state, productName) => {
      state.productName = productName;
    },
    setUser: (state, user) => {
      state.user = user;
    },
    setIsMigrated: (state, isMigrated) => {
      state.isMigrated = isMigrated;
    },
  },
  actions: {
    logIn: ({ commit }, token) => {
      commit('setAuthToken', token);
    },
    logOut: ({ commit }) => {
      commit('setAuthToken', '');
    },
    setProductName: ({ commit }, productName) => {
      commit('setProductName', productName);
    },
    setUser: ({ commit }, user) => {
      commit('setUser', user);
    },
    setIsMigrated: ({ commit }, isMigrated) => {
      commit('setIsMigrated', isMigrated);
    },
  },
  getters: {
    isLoggedIn: (state) => state.authToken && state.authToken != '',
    authToken: (state) => state.authToken,
    productName: (state) => state.productName,
    productDisplayName: (state) => {
      switch (state.productName.toLowerCase()) {
        case 'post':
          return 'Post';
        case 'prepplus':
          return 'Prep+';
        case 'topline':
          return 'TopLine';
        case 'topicpulse':
          return 'TopicPulse';
      }
      return state.productName;
    },
    user: (state) => state.user,
    loginUrl: (state) => state.loginUrl,
    isMigrated: (state) => state.isMigrated,
  },
  modules: {},
  // plugins: [vuexLocal.plugin],
});
