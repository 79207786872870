





























































import 'vue-router';
import LoginContainer from '../components/LoginContainer.vue';
import Message from '../components/Message.vue';
import { gotoOidcHomeForProduct, maskEmail, validatePasswordRequirements } from '../utils';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import UserModel from '../model/UserModel';
import axios from 'axios';
import PasswordInput from '../components/PasswordInput.vue';

@Component({
  components: { PasswordInput, LoginContainer, Message },
})
export default class ResetPassword extends Vue {
  @Getter isLoggedIn!: boolean;
  @Getter user!: UserModel;
  @Getter loginUrl!: string;
  @Getter productName!: string;
  @Action setUser;
  @Action setIsMigrated;

  @Prop({ required: false, default: undefined }) username?: string;
  @Prop({ required: false, default: undefined }) code?: string;

  get hasCode() {
    return this.username?.length && this.code?.length;
  }

  resetCode = '';
  newPassword = '';
  confirmPassword = '';
  canShowErrors = false;
  errors: string[] = [];
  errorToInputMapping = {};

  async sendReset(username: string, code: string, newPassword: string): Promise<string[] | undefined> {
    if (this.errors.length === 0) {
      const payload = {
        email: this.user.email,
        confirmationCode: this.resetCode,
        newPassword: this.newPassword,
      };

      const errors: string[] = [];

      await axios
        .post(`${this.loginUrl}/account/confirmPasswordReset`, payload)
        .then((res) => {
          if (res.data.success) {
            this.user.password = this.newPassword;
            this.setUser(new UserModel());
            window.localStorage['migrated'] = true;
            gotoOidcHomeForProduct(this.productName);
          } else {
            if (res.data.status === 'EXPIRED_CODE' || res.data.status === 'CODE_MISMATCH') {
              // This can mean expired, or can mean no code was requested.
              errors.push(
                // Zena-approved
                'It looks like your unique reset link has expired. Please <a href="/forgot-password">click here</a> to request a fresh link, which must be used within 1 hour.'
              );
            } else {
              // Unknown error, if server provided message, use it.
              // Otherwise, show default.
              errors.push(
                res.data.message ||
                  // Zena-approved
                  'It’s not you, it’s us. An error has occurred, but <a href="/forgot-password">clicking this link</a> to reload the page should take care of the issue.'
              );
            }
          }
        })
        .catch((e) => {
          console.error(e);
          errors.push(
            // Zena-approved
            'It’s not you, it’s us. An error has occurred, but <a href="/forgot-password">clicking this link</a> to reload the page should take care of the issue.'
          );
        });

      if (errors.length) {
        return errors;
      }

      return undefined;
    }
  }

  async confirmClicked() {
    this.canShowErrors = true;
    this.validateForm();

    if (this.errors.length === 0) {
      const username = this.user.email;
      const code = this.resetCode;
      const newPassword = this.newPassword;

      const errors = await this.sendReset(username, code, newPassword);
      if (errors?.length) {
        this.errors = errors;
      }
    }
  }

  validateForm() {
    this.errors = [];
    this.errorToInputMapping = {};
    const valid = validatePasswordRequirements(this.newPassword);

    if (!this.resetCode) {
      this.errors.push('Reset Code is required');
      this.errorToInputMapping['resetCode'] = true;
    }
    if (!valid) {
      this.errors.push('Your password is too short. Please make sure it’s at least 6 characters.');
      this.errorToInputMapping['newPassword'] = true;
    }
    if (!this.confirmPassword || !(this.newPassword === this.confirmPassword)) {
      this.errors.push('Your passwords don’t match.');
      this.errorToInputMapping['newPassword'] = true;
      this.errorToInputMapping['confirmPassword'] = true;
    }
  }

  // ++++++ COMPUTED ++++++ //
  get maskedEmail() {
    if (this.user.email) return maskEmail(this.user.email);
    return '';
  }

  mounted() {
    // Copy values from query parameters first
    if (this.username?.length) {
      this.user.email = this.username;
    }
    if (this.code?.length) {
      this.resetCode = this.code;
    }

    if (!this.user.email) {
      this.$nextTick(() => {
        this.$router.replace({ name: 'Login' });
      });
    }
  }

  @Watch('newPassword')
  onNewPasswordChanged() {
    this.canShowErrors && this.validateForm();
  }

  @Watch('resetCode')
  onResetCodeChanged() {
    this.canShowErrors && this.validateForm();
  }

  @Watch('confirmPassword')
  onConfirmPasswordChanged() {
    this.canShowErrors && this.validateForm();
  }
}
