

















import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import UserModel from '@/model/UserModel';

@Component
export default class LoginContainer extends Vue {
  @Getter productDisplayName!: string;
  @Getter productName!: string;
  @Getter user!: UserModel;
  @Action setUser;

  get productLogo() {
    const productName = this.productName.toLowerCase();
    if (productName === 'topline') {
      return require('@/assets/topline-logo@2x.png');
    } else if (productName === 'topicpulse') {
      return require('@/assets/topicpulse-logo@2x.png');
    } else if (productName === 'post') {
      return require('@/assets/post-logo.svg');
    } else if (productName === 'prepplus') {
      return require('@/assets/prepplus-logo@2x.png');
    }
    return require('@/assets/aec-logo-footer.svg');
  }

  created() {
    const query = this.$route.query;

    if (query.token) {
      this.user.token = `${query.token}`;
    }
    if (query.email) {
      this.user.email = `${query.email}`;
    }
    this.setUser(this.user);
  }

  mounted() {
    this.$nextTick(() => {
      document.title = this.productDisplayName || 'Futuri Login';
    });
  }
}
