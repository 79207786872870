


















import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({})
export default class LoginContainer extends Vue {
  @Prop({ required: true }) messageType!: string;
}
