



















































import 'vue-router';
import LoginContainer from '../components/LoginContainer.vue';
import Message from '../components/Message.vue';
import { Action, Getter } from 'vuex-class';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import axios from 'axios';
import UserModel from '../model/UserModel';
import { validatePasswordRequirements, validateEmailAddress, gotoOidcHomeForProduct } from '../utils';
import PasswordInput from '../components/PasswordInput.vue';
import { SignupFlowResult, SignupFlowStatus, TOPLINE, SignupStatus } from '@futuri/cloudsso-client';

@Component({
  components: { PasswordInput, LoginContainer, Message },
})
export default class SignUp extends Vue {
  @Getter isLoggedIn!: boolean;
  @Getter loginUrl!: string;
  @Getter productName!: string;
  @Getter productDisplayName!: string;
  @Getter user!: UserModel;
  @Action setUser;
  @Action setIsMigrated;

  @Prop({ required: false, default: undefined }) token?: string;

  // emailAddress = '';
  password = '';
  confirmPassword = '';
  formErrors: string[] = [];
  unknownError = false;
  // firstName = '';
  // lastName = '';
  canShowErrors = false;
  isInvitation = false;

  signUpButtonClicked() {
    const productType = this.productName?.toUpperCase() ?? TOPLINE;

    this.formErrors = [];
    this.canShowErrors = true;
    this.validateForm();
    if (this.formErrors.length == 0) {
      const payload = {
        email: this.user.email,
        password: this.password,
      };
      if (this.isInvitation) {
        console.log('signup: invitation: productUsername: ', this.user.productUsername);
        payload['migration'] = {
          details: {
            productType: this.productName,
            productUsername: this.user.productUsername,
          },
          credentials: {
            productToken: this.user.token,
          },
        };
      }
      axios.post(`${this.loginUrl}/account/signup`, payload).then((res) => {
        console.log(res);
        const data = res.data as SignupFlowResult;
        if (data.success || data.status === 'SUCCESS') {
          const message = 'Your new account has been created! Sign in below.'; // Zena-approved
          // this.$router.push({ name: 'ConfirmationCode' });
          this.setIsMigrated(true);
          if (window.localStorage) {
            window.localStorage['migrated'] = true;
            window.localStorage['flash_message'] = message;
          }
          gotoOidcHomeForProduct(this.productName, { message });
        } else {
          switch (data.status) {
            case SignupFlowStatus.NO_PRODUCT_ACCOUNT:
            case SignupFlowStatus.INVALID_PRODUCT_CREDENTIALS:
              this.unknownError = false;
              this.$router.push({
                name: 'SignUpError',
              });
              break;
            case SignupFlowStatus.EMAIL_ALREADY_USED:
              this.unknownError = false;
              this.formErrors.push('Email is already registered. Try signing in.');
              break;
            default:
            case SignupFlowStatus.UNKNOWN_ERROR:
              this.unknownError = true;
              break;
          }
        }
      });
    }
  }

  validateForm() {
    this.formErrors = [];
    this.unknownError = false;
    const valid = validatePasswordRequirements(this.password);

    if (!valid) this.formErrors.push('Your password is too short. Please make sure it’s at least 6 characters.');
    if (this.confirmPassword !== this.password) this.formErrors.push('Your passwords don’t match.');
    // Not requiring first name last name if token is provided
    // As of 2022-02, we always look name/email up from the source product database.
    // if (!this.user.token) {
    //   if (!this.firstName) {
    //     this.formErrors.push('First Name is required');
    //   }
    //   if (!this.lastName) {
    //     this.formErrors.push('Last Name is required');
    //   }
    // }
    // if (!validateEmailAddress(this.user.email)) this.formErrors.push('Valid email is required');
    if (!validateEmailAddress(this.user.email)) this.formErrors.push('This invitiation is invalid.');
  }

  goToHome() {
    gotoOidcHomeForProduct(this.productName);
  }

  mounted() {
    if (this.user) {
      console.log('SignUp: user: ', this.user);
      // this.emailAddress = this.user.email || '';
      this.isInvitation = !!(this.user.token && this.user.token != '');
    }
  }

  @Watch('password')
  onPasswordChanged() {
    this.canShowErrors && this.validateForm();
  }

  @Watch('emailAddress')
  onEmailChanged() {
    this.canShowErrors && this.validateForm();
  }

  @Watch('firstName')
  onFirstNameChanged() {
    this.canShowErrors && this.validateForm();
  }

  @Watch('lastName')
  onLastNameChanged() {
    this.canShowErrors && this.validateForm();
  }
}
